@charset 'UTF-8';

$main: #1da1f2;

/*************************************
    scroll bar
*************************************/
::selection {
  color: #fff;
  background-color: $main;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  height: 50px;
  width: 50px;
  background: $main;
  border-radius: 8px;
}

/*************************************
    common class
*************************************/
.sz-wrap {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
}

.blind {
  visibility: hidden;
  overflow: hidden;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

/*************************************
    navigation
*************************************/
#navigation {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.nav-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.nav-container {
  position: fixed;
  top: 0;
  left: -500px;
  width: 500px;
  height: 100%;
  background-color: #fff;
  z-index: 1;
  transition: left 1.2s 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.nav-header {
  position: relative;
  width: 100%;
  padding: 33px 0;
  background-color: $main;
}

#navSvg {
  position: absolute;
  top: 7px;
  left: 66px;

  svg {
    width: 80px;
    height: 80px;
  }

  path {
    stroke: #fff;
  }
}

#nav {
  padding: 80px;
}

#navigation.active {
  .nav-container {
    left: 0;
  }
}

.tool-category {
  margin-bottom: 36px;
}

.category-name {
  padding-bottom: 10px;
  margin-bottom: 20px;
  color: #acacac;
  border-bottom: 1px solid #acacac;
  font-size: 16px;
  font-weight: 300;
}

.tool-list {
  li {
    margin-bottom: 20px;
    color: #333;
    font-size: 20px;
    font-weight: 300;
    text-indent: 20px;

    a {
      color: inherit;

      &:hover {
        color: $main;
        font-weight: 500;
      }

      &:hover::before {
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 7px;
        background: url(../images/common/deco-icon.png) center center / cover no-repeat;
        vertical-align: 0px;
        animation: rotate 3s linear infinite both;
      }
    }

    .wait {
      color: #acacac;
      font-size: 14px;
    }
  }
}

/*************************************
    버튼
*************************************/
.default-btn {
  padding: 7px 25px;
  color: #333;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid $main;
  border-radius: 20px;
  letter-spacing: -0.64px;
  vertical-align: middle;
  font-family: "S-CoreDream";
  transition: background-color 0.3s;

  &:focus,
  &:hover {
    color: #fff;
    background-color: $main;
  }
}

/*************************************
    common layout
*************************************/
#container {
  min-height: calc(100vh - 175px);
}

.content-wrap {
  padding-top: 40px;
  padding-bottom: 100px;
}

.top-title {
  padding: 70px 0;
  color: #fff;
  background-color: #1da1f2;
  text-align: center;

  h2 {
    margin-bottom: 7px;
    font-size: 32px;
    font-weight: 500;
  }

  p {
    line-height: 1.3;
    font-size: 20px;
    font-weight: 300;
  }

  &.design {
    background-color: #ff2a38;
  }

  &.font {
    background-color: #0f4c81;
  }

  &.css {
    background-color: #f8c534;
  }
}

.gray-wrap {
  padding: 40px;
  background-color: #f1f1f1;
  border-radius: 20px;
  text-align: center;

  &.top {
    margin-bottom: 40px;
  }

  .gray-title {
    color: #333;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 40px;
  }
}

.sub-title {
  margin-bottom: 28px;
  color: #333;
  font-size: 20px;
  font-weight: 500;
}

.sub-text {
  margin-top: 30px;
  line-height: 1.5;
  color: #333;
  font-size: 16px;
  font-weight: 300;
}

.input-wrap {
  padding: 22px 40px;
  background-color: #d2ecfc;
  border-radius: 40px;

  label {
    display: inline-block;
    margin-right: 15px;
    color: #333;
    font-size: 20px;
    font-weight: 500;
    vertical-align: middle;
  }

  .input-box {
    display: inline-block;
    position: relative;
  }

  input {
    width: 248px;
    height: 36px;
    padding: 0 45px 0 30px;
    color: #333;
    border: 1px solid transparent;
    border-radius: 18px;

    &:focus {
      border: 1px solid $main;
      outline: none;
    }
  }

  .input-text {
    width: 100%;
    height: 40px;
  }

  .input-tail {
    position: absolute;
    top: 4px;
    right: 18px;
    color: #999;
    font-size: 16px;
  }
}

.input-wrap.full {
  width: 100%;

  .input-box {
    width: 100%;
  }
}

.input-wrap.white {
  display: inline-block;
  background-color: #fff;

  label {
    font-size: 18px;
    font-weight: 400;
  }

  input {
    border: 1px solid #acacac;

    &:focus {
      border: 1px solid $main;
      outline: none;
    }
  }

  .default-btn {
    margin-left: 15px;
  }
}

.flex-box {
  display: flex;
  margin-left: -20px;

  .flex-item {
    flex: 1;
    align-self: stretch;
    padding-left: 20px;
  }
}

.right_sidebar {
  margin-bottom: 40px;

  .flex-item {
    &:first-child {
      flex-basis: 70%;
    }
  }

  .flex-item {
    &:last-child {
      flex-basis: 30%;
    }
  }
}

.dashed-wrap {
  height: 100%;
  padding: 60px;
  border: 2px dashed #acacac;
  border-radius: 60px;
  text-align: center;

  &.left {
    text-align: left;
  }
}

.content-title {
  margin-bottom: 30px;
  color: #333;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: -1.2px;
}

.deco-text {
  &::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 7px;
    background: url(../images/common/deco-icon.png) center center / cover no-repeat;
    vertical-align: -2px;
  }
}

.info-wrap {
  margin-top: 40px;
}

.info-title {
  margin-bottom: 17px;
  color: $main;
  font-size: 20px;
  font-weight: 500;
}

.info-list {
  display: inline-block;

  li {
    line-height: 1.5;
    color: #333;
    font-size: 16px;
    font-weight: 300;
    text-align: left;
  }
}

.result-wrap {
  width: 100%;
  max-width: 1180px;
  padding: 20px 10px;
  margin: 0 auto 40px;
  color: #333;
  background-color: #d2ecfc;
  border-radius: 40px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;

  .result {
    display: inline-block;
    margin-left: 25px;
    font-size: 30px;
    vertical-align: -3px;
  }
}

form {
  .default-btn {
    display: block;
    width: 100%;
    max-width: 1180px;
    margin: 40px auto;
  }
}

.link-list {
  margin-left: -20px;

  &::after {
    content: "";
    display: block;
    clear: both;
  }

  li {
    float: left;
    width: 33.33%;
    height: 300px;
    padding-left: 20px;
    margin-top: 30px;

    .dashed-wrap {
      padding: 30px 40px;
      transition: box-shadow 0.3s;
    }

    .content-title {
      padding-bottom: 25px;
      margin-bottom: 15px;
      border-bottom: 1px solid #acacac;
      text-align: left;

      &::after {
        content: "";
        display: block;
        clear: both;
      }
    }

    .font-title {
      color: #1da1f2;
      font-size: 24px;
      font-weight: 500;
    }

    .font-company {
      display: block;
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 400;
    }

    .font-text {
      line-height: 1.5;
      height: 120px;
      font-size: 40px;
      text-align: left;
      word-break: break-all;
      overflow: auto;
    }

    &:hover {
      .dashed-wrap {
        box-shadow: 6.3px 7.8px 10px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }
}

.menu-wrap {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.9);
  // height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.menu-type {
  &.css {
    // position: sticky;
    // z-index: 1;
    // top: 30px;
    margin: 30px 0 15px;

    &.category {
      top: 65px;
      margin: 0 0 30px;

      li {
        font-size: 0.8rem;
      }
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 15px;
      padding: 0 20px;

      li {
        cursor: pointer;
        padding: 0.2rem 0.3rem;

        &:hover {
          transition: 0.3s;
          background-color: red;
          // border-radius: 5px;
          color: #fff;
        }

        &.checked {
          background-color: red;
          // border-radius: 5px;
          color: #fff;
        }
      }
    }
  }
}

.Hello {
  height: 1000vh;
}

.css-reference-content {
  padding: 0 3rem 3rem;
  overflow: hidden;

  .button-toggle {
    background: #23d160;
    color: #fff;
    font-size: 0.8rem;
    border-radius: 3px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    font-weight: 700;
    padding-left: 1.2em;
    padding-right: 1.2em;
    text-align: center;
    transition-duration: 300ms;
    transition-property:
      background,
      box-shadow,
      color,
      transform,
      -webkit-transform;
    will-change: box-shadow;
    border: none;
    line-height: 1.6;
    padding: 0.6em 1em;
    display: inline-block;
    margin-top: 1rem;

    &.positioning {
      &::before {
        content: "Enable position fixed";
      }
    }

    &.play-ani {
      &::before {
        content: "▶ Play animations";
      }

      &.off {
        background: #ff4538;

        &::before {
          content: "￭ Stop";
        }
      }
    }

    &:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      transform: translateY(-1px);
    }
  }

  .name {
    font-size: 1.25rem;
    font-weight: 700;
    background-color: #d2ecfc;
    border-radius: 40px;
    text-align: center;
    padding: 1.25rem 0;
  }

  .define {
    font-size: 1.2rem;
    text-align: center;
    margin-top: 0.5rem;
  }

  .contents-tab {
    display: flex;
    align-items: flex-start;
    gap: 2rem;
    padding: 2rem 0 0;

    .property {
      &-name {
        background: #ffdd57;
        border-radius: 2px;
        color: rgba(0, 0, 0, 0.7);
        display: inline-block;
        padding: 0.3rem;
        vertical-align: top;
        cursor: pointer;
        position: relative;

        &:hover::before {
          opacity: 1;
          visibility: visible;
        }

        &::before {
          content: "Click to copy";
          background-color: #333;
          border-radius: 2px;
          bottom: 100%;
          color: #fff;
          font-size: 0.6rem;
          font-weight: 400;
          left: 50%;
          transform: translate(-50%, -4px);
          line-height: 1.2;
          padding: 0.5em 0.6em;
          pointer-events: none;
          position: absolute;
          text-align: center;
          white-space: nowrap;

          opacity: 0;
          visibility: hidden;
        }

        &.copied {
          &::before {
            content: "Copied!";
            background: #23d160;
          }
        }

        &-wrap {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        &-default {
          box-shadow: inset 0 0 0 1px #ff4538;
          color: #ff4538;
          display: inline-block;
          padding: 0.1em 0.5em 0.15em;
          vertical-align: top;
          border-radius: 2px;
          cursor: pointer;
          position: relative;
          // font-size: 0.8rem;

          &.style2 {
            box-shadow: inset 0 0 0 1px #23d160;
            color: #23d160;
          }

          &-hover {
            position: absolute;
            top: -30px;
            left: 50%;
            transform: translateX(-50%);
            width: 200px;
            text-align: center;
            display: block;
            font-size: 0.7rem;
            color: #fff;
            background-color: #333;
            padding: 0.3rem;
            border-radius: 3px;
            opacity: 0;
            transition: 0.2s;
          }

          &:hover {
            .property-name-default-hover {
              opacity: 1;
            }
          }
        }
      }

      &-define {
        margin-top: 0.5em;

        ul {
          margin-left: 1.5rem;

          li {
            list-style: disc;
            line-height: 1.5;
          }
        }
      }
    }

    .contents {
      &-title {
        width: 60%;
      }

      &-example {
        width: 40%;
      }

      &-output {
        .example-background-output {
          padding: 0.8rem 1rem;

          strong {
            font-weight: bold;
          }

          &.p-basic {
            padding: 0;
          }

          //backgrounds
          &.attachment {
            background: url(../../assets/images/common/ex-bg-img.png) center center / cover no-repeat;
            height: 200px;

            p {
              background-color: #fff;
              padding: 0.5rem;
            }

            &.scroll {
              background-attachment: scroll;
            }

            &.fixed {
              background-attachment: fixed;
            }
          }

          &.clip {
            background: #05ffb0;
            border: 4px dashed;
            padding: 2em;

            &.border-box {
              background-clip: border-box;
            }

            &.padding-box {
              background-clip: padding-box;
            }

            &.content-box {
              background-clip: content-box;
            }
          }

          &.color {
            &.transparent {
              background-color: transparent;
            }

            &.red {
              background-color: red;
            }

            &.select {
              background-color: #05ffb0;
            }

            &.rgb {
              background-color: rgb(50, 115, 220);
            }

            &.rgba {
              background-color: rgba(50, 115, 220, 0.3);
            }

            &.hsl {
              background-color: hsl(14, 100%, 53%);
            }

            &.hsla {
              background-color: hsla(14, 100%, 53%, 0.6);
            }
          }

          &.image {
            height: 200px;

            &.none {
              background-image: none;
            }

            &.url {
              background-image: unset;
            }

            &.gradient1 {
              background-image: linear-gradient(red, blue);
            }

            &.gradient2 {
              background-image: linear-gradient(45deg, red, blue);
            }

            &.r-gradient1 {
              background-image: radial-gradient(green, purple);
            }

            &.r-gradient2 {
              background-image: radial-gradient(circle, green, purple);
            }

            &.r-gradient3 {
              background-image: radial-gradient(circle, green 0%, purple 20%, orange 100%);
            }

            &.r-gradient4 {
              background-image: radial-gradient(circle closest-side, green 0%, purple 20%, orange 100%);
            }

            &.r-gradient5 {
              background-image: radial-gradient(circle closest-side at 45px 45px, green 0%, purple 20%, orange 100%);
            }
          }

          &.origin {
            background-image: url(../../assets/images/common/ex-bg-img.png);
            background-repeat: no-repeat;
            background-size: 64px 64px;
            border: 4px dashed;
            padding: 2em;

            p {
              background-color: rgba(5, 255, 176, 0.5);
              padding: 1em;
            }

            &.padding-box {
              background-origin: padding-box;
            }

            &.border-box {
              background-origin: border-box;
            }

            &.content-box {
              background-origin: content-box;
            }
          }

          &.position {
            background-image: url(../../assets/images/common/ex-bg-img.png);
            background-repeat: no-repeat;
            background-size: 64px 64px;
            height: 150px;

            &.type1 {
              background-position: 0% 0%;
            }

            &.type2 {
              background-position: bottom right;
            }
            &.type3 {
              background-position: center center;
            }
          }

          &.repeat {
            background-image: url(../../assets/images/common/ex-bg-img.png);
            background-size: 64px 64px;
            height: 150px;

            &.repeat {
              background-repeat: repeat;
            }

            &.repeat-x {
              background-repeat: repeat-x;
            }
            &.repeat-y {
              background-repeat: repeat-y;
            }
            &.no-repeat {
              background-repeat: no-repeat;
            }
          }

          &.size {
            background-image: url(../../assets/images/common/ex-bg-img.png);
            background-size: 64px 64px;
            height: 150px;

            &.auto {
              background-size: auto auto;
            }
            &.pixel {
              background-size: 120px 80px;
            }
            &.percentage {
              background-size: 100% 50%;
            }
            &.contain {
              background-size: contain;
            }
            &.cover {
              background-size: cover;
            }
          }

          //Box model
          .border {
            border-style: solid;
            padding: 0.8em 1em;

            &.bottom-width {
              &.type1 {
                border-bottom-width: 0;
              }
              &.type2 {
                border-bottom-width: 4px;
              }
            }

            &.left-width {
              &.type1 {
                border-left-width: 0;
              }
              &.type2 {
                border-left-width: 4px;
              }
            }

            &.right-width {
              &.type1 {
                border-right-width: 0;
              }
              &.type2 {
                border-right-width: 4px;
              }
            }

            &.top-width {
              &.type1 {
                border-top-width: 0;
              }
              &.type2 {
                border-top-width: 4px;
              }
            }
          }

          .box-sizing {
            padding: 0.8em 1em;
            position: relative;
            &.type1 {
              box-sizing: content-box;
            }

            &.type2 {
              box-sizing: border-box;

              .actual {
                box-sizing: border-box;

                .box-red {
                  height: 33px;
                  left: -12px;
                  top: 30px;
                }
              }
            }

            .actual {
              border: 12px solid #310736;
              display: block;
              padding: 30px;
              position: relative;
              width: 200px;
              text-align: center;
              background: #05ffb0;
              color: #310736;
              box-sizing: content-box;

              span {
                background: hsl(48, 100%, 67%);
                display: block;
                padding: 5px 0;
              }

              .box-red {
                border: 2px solid #ff4538;
                border-radius: 3px;
                height: 30px;
                position: absolute;
                text-align: left;
                left: 30px;
                top: 30px;
                width: 196px;

                &::before {
                  content: "200px";
                  background: #ff4538;
                  border-radius: 1px 0 2px 0;
                  color: #fff;
                  display: inline-block;
                  font-size: 0.8em;
                  padding: 0 6px 2px 4px;
                  vertical-align: top;
                }
              }
            }
          }

          &.height {
            max-height: 400px;

            .height {
              display: block;
              line-height: 1.2;
              padding: 1em;
              border-radius: 3px;
              background: #05ffb0;
              color: #310736;
              &.type1 {
                height: auto;
              }
              &.type2 {
                height: 100px;
              }
            }
          }

          .line-height {
            background: #05ffb0;
            color: #310736;
            padding: 1em;
            display: block;
            border-radius: 3px;

            &.type1 {
              line-height: normal;
            }

            &.type2 {
              line-height: 1.6;
            }

            &.type3 {
              line-height: 30px;
            }

            &.type4 {
              line-height: 0.8em;
            }
          }

          .margin {
            background: hsl(0, 0%, 80%);
            padding: 1em;

            .block {
              border-radius: 0;
              padding: 0;

              &.block {
                background: #f2f2f2;
                color: #4d4d4d;
                display: block;
                line-height: 1.2;
                padding: 1em;

                &-pink {
                  background: #ff3860;
                  color: #310736;
                }

                &-alpha {
                  position: relative;
                  background: #05ffb0;
                  color: #310736;
                }

                &-yellow {
                  background: #ffdd57;
                  color: #310736;
                }
              }
            }

            .target {
              bottom: 0;
              left: 0;
              position: absolute;
              right: 0;
              transform: translateY(100%);
              border: 2px solid #4d4d4d;
              border-radius: 3px;

              &.block-plum {
                border-color: #310736;
              }

              &::before {
                background-color: #310736;
                border-radius: 1px 0 2px 0;
                color: #fff;
                content: "test";
                display: inline-block;
                font-size: 0.8em;
                padding: 0 6px 2px 4px;
                vertical-align: top;
              }
            }

            &.bottom {
              &.type1 {
                .block {
                  .target {
                    display: none;
                  }
                }
              }

              &.type2 {
                .block {
                  &.block-alpha {
                    margin-bottom: 30px;
                  }

                  .target {
                    height: 30px;
                    &::before {
                      content: "30px";
                    }
                  }
                }
              }

              &.type3 {
                .block {
                  &.block-alpha {
                    margin-bottom: 2em;
                  }

                  .target {
                    height: 2em;
                    &::before {
                      content: "2em";
                    }
                  }
                }
              }

              &.type4 {
                .block {
                  &.block-alpha {
                    margin-bottom: 10%;
                  }

                  .target {
                    bottom: auto;
                    height: 100px;
                    top: 100%;
                    transform: rotate(-90deg) translateX(-100%);
                    transform-origin: top left;
                    width: 10%;

                    &::before {
                      content: "10%";
                    }

                    &.block-plum {
                      border-color: #310736;
                    }
                  }
                }
              }
            }

            &.left {
              .target {
                bottom: 0;
                left: 0;
                position: absolute;
                top: 0;
                transform: translateX(-100%);
              }

              &.type1 {
                .target {
                  display: none;
                }
              }

              &.type2 {
                .block {
                  &.block-alpha {
                    margin-left: 50px;
                  }

                  .target {
                    width: 50px;

                    &::before {
                      content: "50px";
                    }
                  }
                }
              }

              &.type3 {
                .block {
                  &.block-alpha {
                    margin-left: 7em;
                  }

                  .target {
                    width: 7em;

                    &::before {
                      content: "7em";
                    }
                  }
                }
              }

              &.type4 {
                .m-position {
                  position: relative;
                }

                .block {
                  &.block-alpha {
                    margin-left: 30%;
                  }
                }

                .target {
                  width: 30%;
                  height: 2.4em;
                  left: 0;
                  top: 3.2em;
                  transform: none;

                  &::before {
                    content: "30%";
                  }
                }
              }

              &.type5 {
                .m-position {
                  position: relative;
                }

                .block {
                  &.block-alpha {
                    margin-left: auto;
                    width: 200px;
                  }
                }

                .target {
                  height: 2.4em;
                  left: 0;
                  right: 200px;
                  top: 3.2em;
                  transform: none;
                  width: auto;

                  &::before {
                    content: "auto";
                  }
                }
              }
            }

            &.right {
              .target {
                bottom: 0;
                position: absolute;
                right: 0;
                top: 0;
                left: unset;
                transform: translateX(100%);
              }

              &.type1 {
                .target {
                  display: none;
                }
              }

              &.type2 {
                .block {
                  &.block-alpha {
                    margin-right: 50px;
                  }

                  .target {
                    width: 50px;

                    &::before {
                      content: "50px";
                    }
                  }
                }
              }

              &.type3 {
                .block {
                  &.block-alpha {
                    margin-right: 7em;
                  }

                  .target {
                    width: 7em;

                    &::before {
                      content: "7em";
                    }
                  }
                }
              }

              &.type4 {
                .m-position {
                  position: relative;
                }

                .block {
                  &.block-alpha {
                    margin-right: 30%;
                  }
                }

                .target {
                  width: 30%;
                  height: 2.4em;
                  right: 0;
                  top: 3.2em;
                  transform: none;

                  &::before {
                    content: "30%";
                  }
                }
              }

              &.type5 {
                .m-position {
                  position: relative;
                }

                .block {
                  &.block-alpha {
                    margin-right: auto;
                    width: 200px;
                  }
                }

                .target {
                  height: 2.4em;
                  left: 200px;
                  right: 0;
                  top: 3.2em;
                  transform: none;
                  width: auto;

                  &::before {
                    content: "auto";
                  }
                }
              }
            }

            &.top {
              .target {
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
                transform: translateY(-100%);
              }

              &.type1 {
                .block {
                  .target {
                    display: none;
                  }
                }
              }

              &.type2 {
                .block {
                  &.block-alpha {
                    margin-top: 30px;
                  }

                  .target {
                    height: 30px;
                    &::before {
                      content: "30px";
                    }
                  }
                }
              }

              &.type3 {
                .block {
                  &.block-alpha {
                    margin-top: 2em;
                  }

                  .target {
                    height: 2em;
                    &::before {
                      content: "2em";
                    }
                  }
                }
              }

              &.type4 {
                .block {
                  &.block-alpha {
                    margin-top: 10%;
                  }

                  .target {
                    height: 100%;
                    transform: rotate(-90deg);
                    transform-origin: top left;
                    width: 10%;

                    &::before {
                      content: "10%";
                    }

                    &.block-plum {
                      border-color: #310736;
                    }
                  }
                }
              }
            }

            &.basic {
              .container {
                margin-left: auto;
                margin-right: auto;
                max-width: 700px;
                align-items: stretch;
                display: flex;
              }

              .block.block-alpha {
                flex-grow: 1;
                flex-shrink: 1;
              }

              .square {
                line-height: 1.2;
                padding: 0;
                color: #4d4d4d;
                text-align: center;
                background: #e6e6e6;
                align-items: center;
                border-radius: 0;
                display: flex;
                height: auto;
                justify-content: center;
                width: auto;

                &.square {
                  font-size: 0.8em;

                  &-top {
                    height: 30px;

                    &::before {
                      content: "30px";
                    }
                  }

                  &-left {
                    width: 30px;
                    &::before {
                      content: "30px";
                    }
                  }

                  &-right {
                    width: 30px;
                    &::before {
                      content: "30px";
                    }
                  }

                  &-bottom {
                    height: 30px;
                    &::before {
                      content: "30px";
                    }
                  }
                }
              }

              &.type3 {
                .square {
                  &.square {
                    &-top {
                      height: 30px;

                      &::before {
                        content: "30px";
                      }
                    }

                    &-left {
                      width: 60px;
                      &::before {
                        content: "60px";
                      }
                    }

                    &-right {
                      width: 60px;
                      &::before {
                        content: "60px";
                      }
                    }

                    &-bottom {
                      height: 30px;
                      &::before {
                        content: "30px";
                      }
                    }
                  }
                }
              }

              &.type4 {
                .square {
                  &.square {
                    &-top {
                      height: 30px;

                      &::before {
                        content: "30px";
                      }
                    }

                    &-left {
                      width: 60px;
                      &::before {
                        content: "60px";
                      }
                    }

                    &-right {
                      width: 60px;
                      &::before {
                        content: "60px";
                      }
                    }

                    &-bottom {
                      height: 45px;
                      &::before {
                        content: "45px";
                      }
                    }
                  }
                }
              }

              &.type5 {
                .square {
                  &.square {
                    &-top {
                      height: 30px;

                      &::before {
                        content: "30px";
                      }
                    }

                    &-left {
                      width: 85px;
                      &::before {
                        content: "85px";
                      }
                    }

                    &-right {
                      width: 60px;
                      &::before {
                        content: "60px";
                      }
                    }

                    &-bottom {
                      height: 45px;
                      &::before {
                        content: "45px";
                      }
                    }
                  }
                }
              }
            }
          }

          .max-height {
            display: block;
            line-height: 1.2;
            padding: 1em;
            border-radius: 3px;
            background: #05ffb0;
            color: #310736;
            &.type1 {
              max-height: none;
            }

            &.type2 {
              max-height: 2000px;
            }

            &.type3 {
              max-height: 100px;
            }
          }

          .max-width {
            display: block;
            line-height: 1.2;
            padding: 1em;
            border-radius: 3px;
            background: #05ffb0;
            color: #310736;
            &.type1 {
              max-width: none;
            }
            &.type2 {
              max-width: 2000px;
            }
            &.type3 {
              max-width: 150px;
            }
          }

          .min-height {
            display: block;
            line-height: 1.2;
            padding: 1em;
            border-radius: 3px;
            background: #05ffb0;
            color: #310736;
            &.type1 {
              min-height: 0;
            }
            &.type2 {
              min-height: 250px;
            }
            &.type3 {
              min-height: 5px;
            }
          }

          .min-width {
            display: block;
            line-height: 1.2;
            padding: 1em;
            border-radius: 3px;
            background: #05ffb0;
            color: #310736;
            &.type1 {
              display: inline-block;
              min-width: 0;
            }
            &.type2 {
              display: inline-block;
              min-width: 300px;
            }
            &.type3 {
              display: inline-block;
              min-width: 5px;
            }
          }

          .padding {
            background: hsl(0, 0%, 80%);
            padding: 1em;

            .block {
              border-radius: 0;
              padding: 0;

              &.block {
                background: #f2f2f2;
                color: #4d4d4d;
                display: block;
                line-height: 1.2;
                // border-radius: 3px;
                // padding: 1em;

                &-pink {
                  background: #ff3860;
                  color: #310736;
                }

                &-alpha {
                  position: relative;
                  background: #05ffb0;
                  color: #310736;
                }

                &-yellow {
                  background: #ffdd57;
                  color: #310736;
                }
              }
            }

            .target {
              bottom: 0;
              left: 0;
              position: absolute;
              right: 0;
              border: 2px solid #4d4d4d;
              border-radius: 3px;

              &.block-plum {
                border-color: #310736;
              }

              &::before {
                background-color: #310736;
                border-radius: 1px 0 2px 0;
                color: #fff;
                content: "test";
                display: inline-block;
                font-size: 0.8em;
                padding: 0 6px 2px 4px;
                vertical-align: top;
              }
            }

            &.bottom {
              &.type1 {
                .block {
                  .target {
                    display: none;
                  }
                }
              }

              &.type2 {
                .block {
                  &.block-alpha {
                    padding-bottom: 50px;
                  }

                  .target {
                    height: 50px;
                    &::before {
                      content: "50px";
                    }
                  }
                }
              }

              &.type3 {
                .block {
                  &.block-alpha {
                    padding-bottom: 7em;
                  }

                  .target {
                    height: 7em;
                    &::before {
                      content: "7em";
                    }
                  }
                }
              }

              &.type4 {
                .block {
                  &.block-alpha {
                    padding-bottom: 30%;
                  }

                  .target {
                    height: 30%;
                    &::before {
                      content: "30%";
                    }

                    &.block-plum {
                      bottom: 0;
                      height: 60px;
                      transform: rotate(-90deg) translateY(60px);
                      transform-origin: bottom left;
                      width: 30%;
                    }
                  }
                }
              }
            }

            &.left {
              .block {
                padding: 1em;
              }

              &.type1 {
                .target {
                  display: none;
                }
              }

              &.type2 {
                .block {
                  &.block-alpha {
                    padding-left: 50px;
                  }

                  .target {
                    width: 50px;
                    height: 100%;

                    &::before {
                      content: "50px";
                    }
                  }
                }
              }

              &.type3 {
                .block {
                  &.block-alpha {
                    padding-left: 7em;
                  }

                  .target {
                    width: 7em;
                    height: 100%;

                    &::before {
                      content: "7em";
                    }
                  }
                }
              }

              &.type4 {
                .block {
                  &.block-alpha {
                    padding-left: 30%;
                  }

                  .target {
                    width: 30%;
                    height: 100%;

                    &::before {
                      content: "30%";
                    }
                  }
                }
              }
            }

            &.right {
              .block {
                padding: 1em;
                text-align: right;

                .target {
                  bottom: 0;
                  right: 0;
                  position: absolute;
                  top: 0;
                  left: unset;
                }
              }

              &.type1 {
                .target {
                  display: none;
                }
              }

              &.type2 {
                .block {
                  &.block-alpha {
                    padding-right: 50px;
                  }

                  .target {
                    width: 50px;
                    height: 100%;

                    &::before {
                      content: "50px";
                    }
                  }
                }
              }

              &.type3 {
                .block {
                  &.block-alpha {
                    padding-right: 7em;
                  }

                  .target {
                    width: 7em;
                    height: 100%;

                    &::before {
                      content: "7em";
                    }
                  }
                }
              }

              &.type4 {
                .block {
                  &.block-alpha {
                    padding-right: 30%;
                  }

                  .target {
                    width: 30%;
                    height: 100%;

                    &::before {
                      content: "30%";
                    }
                  }
                }
              }
            }

            &.top {
              .block {
                .target {
                  bottom: unset;
                  top: 0;
                }
              }

              &.type1 {
                .block {
                  .target {
                    display: none;
                  }
                }
              }

              &.type2 {
                .block {
                  &.block-alpha {
                    padding-top: 50px;
                  }

                  .target {
                    height: 50px;
                    &::before {
                      content: "50px";
                    }
                  }
                }
              }

              &.type3 {
                .block {
                  &.block-alpha {
                    padding-top: 7em;
                  }

                  .target {
                    height: 7em;
                    &::before {
                      content: "7em";
                    }
                  }
                }
              }

              &.type4 {
                .block {
                  &.block-alpha {
                    padding-top: 30%;
                  }

                  .target {
                    height: 30%;

                    &.block-plum {
                      top: 60px;
                      bottom: unset;
                    }

                    &::before {
                      content: "30%";
                    }

                    &.block-plum {
                      height: 60px;
                      top: 0;
                      transform: rotate(-90deg) translateX(-100%);
                      transform-origin: top left;
                      width: 30%;
                    }
                  }
                }
              }
            }

            &.basic {
              background: unset;
              padding: 0;

              .container {
                align-items: stretch;
                display: flex;

                margin-left: auto;
                margin-right: auto;
                max-width: 700px;
              }

              .block.block-alpha {
                flex-grow: 1;
                flex-shrink: 1;
              }

              .square {
                line-height: 1.2;
                padding: 0;
                background: #03b27b;
                text-align: center;
                align-items: center;
                border-radius: 0;
                color: #4d4d4d;
                display: flex;
                height: auto;
                justify-content: center;
                width: auto;

                &.square {
                  font-size: 0.8em;

                  &-top {
                    height: 30px;

                    &::before {
                      content: "30px";
                    }
                  }

                  &-left {
                    width: 30px;
                    &::before {
                      content: "30px";
                    }
                  }

                  &-right {
                    width: 30px;
                    &::before {
                      content: "30px";
                    }
                  }

                  &-bottom {
                    height: 30px;
                    &::before {
                      content: "30px";
                    }
                  }
                }
              }

              &.type3 {
                .square {
                  &.square {
                    &-top {
                      height: 30px;

                      &::before {
                        content: "30px";
                      }
                    }

                    &-left {
                      width: 60px;
                      &::before {
                        content: "60px";
                      }
                    }

                    &-right {
                      width: 60px;
                      &::before {
                        content: "60px";
                      }
                    }

                    &-bottom {
                      height: 30px;
                      &::before {
                        content: "30px";
                      }
                    }
                  }
                }
              }

              &.type4 {
                .square {
                  &.square {
                    &-top {
                      height: 30px;

                      &::before {
                        content: "30px";
                      }
                    }

                    &-left {
                      width: 60px;
                      &::before {
                        content: "60px";
                      }
                    }

                    &-right {
                      width: 60px;
                      &::before {
                        content: "60px";
                      }
                    }

                    &-bottom {
                      height: 45px;
                      &::before {
                        content: "45px";
                      }
                    }
                  }
                }
              }

              &.type5 {
                .square {
                  &.square {
                    &-top {
                      height: 30px;

                      &::before {
                        content: "30px";
                      }
                    }

                    &-left {
                      width: 85px;
                      &::before {
                        content: "85px";
                      }
                    }

                    &-right {
                      width: 60px;
                      &::before {
                        content: "60px";
                      }
                    }

                    &-bottom {
                      height: 45px;
                      &::before {
                        content: "45px";
                      }
                    }
                  }
                }
              }
            }
          }

          .width {
            display: block;
            line-height: 1.2;
            padding: 1em;
            border-radius: 3px;
            background: #05ffb0;
            color: #310736;

            &.type1 {
              width: auto;
            }
            &.type2 {
              width: 240px;
            }
            &.type3 {
              width: 50%;
            }
          }

          //flex box
          &.align {
            background: hsl(0, 0%, 95%);
            display: flex;
            flex-wrap: wrap;
            height: 300px;
            padding: 0;
            position: relative;

            .square {
              height: 46px;
              margin: 2px;
              width: 30%;
              justify-content: center;
              line-height: 1.2;
              padding: 0;
              display: flex;
              border-radius: 3px;
              text-align: center;
              align-items: center;

              &:nth-child(2) {
                height: 96px;
              }

              &.square-plum {
                background: #310736;
                color: #fff;
              }
            }

            .box {
              height: 150px;
              left: 0;
              position: absolute;
              right: 0;
              top: 0;
              border: 2px solid #4d4d4d;
              border-radius: 3px;

              &::before {
                background: #4d4d4d;
                border-radius: 1px 0 2px 0;
                color: #fff;
                content: "";
                display: inline-block;
                font-size: 0.8em;
                padding: 0 6px 2px 4px;
                vertical-align: top;
              }

              &.box-red {
                height: 175px;
                border-color: #ff4538;

                &::before {
                  content: "First line";
                  background: #ff4538;
                }
              }

              &.box-green {
                height: 125px;
                top: 175px;
                border-color: #23d160;

                &::before {
                  content: "Second line";
                  background: #23d160;
                }
              }
            }

            .line {
              left: 2px;
              position: absolute;
              right: 2px;
              height: 1px;
              top: 0;

              &.line-red {
                background: #ff4538;

                &::before {
                  background: #ff4538;
                  border-radius: 2px;
                  color: #fff;
                  content: "Line";
                  display: inline-block;
                  font-size: 10px;
                  height: 15px;
                  line-height: 15px;
                  margin-top: -6px;
                  padding: 0 4px;
                  vertical-align: top;
                }
              }

              &.line-default {
                background: #4d4d4d;

                &::before {
                  background: #4d4d4d;
                  border-radius: 2px;
                  color: #fff;
                  content: "Items";
                  display: inline-block;
                  font-size: 10px;
                  height: 15px;
                  line-height: 15px;
                  margin-top: -6px;
                  padding: 0 4px;
                  vertical-align: top;
                }
              }
            }

            &.content {
              align-content: stretch;
              &.type2 {
                align-content: flex-start;
                .box.box-red {
                  height: 100px;
                }

                .box.box-green {
                  height: 50px;
                  top: 100px;
                }
              }

              &.type3 {
                align-content: flex-end;
                .box.box-red {
                  height: 100px;
                  top: 150px;
                }

                .box.box-green {
                  height: 50px;
                  top: 250px;
                }
              }

              &.type4 {
                align-content: center;
                .box.box-red {
                  height: 100px;
                  top: 75px;
                }

                .box.box-green {
                  height: 50px;
                  top: 175px;
                }
              }

              &.type5 {
                align-content: space-between;
                .box.box-red {
                  height: 100px;
                  top: 0;
                }

                .box.box-green {
                  height: 50px;
                  top: 250px;
                }
              }

              &.type6 {
                align-content: space-around;
                .box.box-red {
                  height: 100px;
                  top: 38px;
                }

                .box.box-green {
                  height: 50px;
                  top: 213px;
                }
              }
            }

            &.items {
              align-items: flex-start;

              .square {
                height: 50px;
                margin: 0 2px;
                width: calc(20% - 4px);

                &:nth-child(2) {
                  height: 100px;
                }

                &:nth-child(4) {
                  height: 75px;
                }
              }

              &.flex-end {
                align-items: flex-end;

                .line {
                  top: unset;
                  bottom: 0;
                }
              }

              &.center {
                align-items: center;

                .line {
                  top: 50%;
                  transform: translateY(-50%);
                }
              }

              &.baseline {
                align-items: baseline;

                .line {
                  top: 55px;
                }
              }

              &.stretch {
                align-items: stretch;

                .square {
                  height: auto;
                }

                .line {
                  display: none;
                }
              }
            }

            &.self {
              align-self: auto;
              align-items: center;
              background: hsl(0, 0%, 95%);
              display: flex;
              height: 200px;
              justify-content: center;
              padding: 0;

              .square {
                height: 50px;
                margin: 0 2px;
                width: calc(20% - 4px);

                &.target {
                  background: #05ffb0;
                  color: #310736;
                  align-self: auto;
                }

                &:nth-child(4) {
                  height: 75px;
                }
              }

              .line {
                &.line-default {
                  top: 50%;
                }

                &.line-red {
                  top: 50%;

                  &::before {
                    content: "Self";
                  }
                }
              }

              &.flex-start {
                .square.target {
                  align-self: flex-start;
                }
              }

              &.flex-end {
                .square.target {
                  align-self: flex-end;
                }

                .line.line-red {
                  top: unset;
                  bottom: 0;
                }
              }

              &.center {
                align-items: flex-start;
                .square.target {
                  align-self: center;
                }

                .line.line-default {
                  top: 0;
                }

                .line.line-red {
                  top: 50%;
                }
              }

              &.baseline {
                .square.target {
                  align-self: baseline;
                }

                .line.line-red {
                  top: 30px;
                }
              }

              &.stretch {
                .square.target {
                  align-self: stretch;
                  height: auto;
                }

                .line.line-red {
                  display: none;
                }
              }
            }
          }

          &.flex {
            background: hsl(0, 0%, 95%);
            display: flex;
            padding: 1em;
            word-break: break-all;

            .block {
              background: #f2f2f2;
              border-radius: 3px;
              color: #4d4d4d;
              display: block;
              line-height: 1.2;
              padding: 1em;
              flex-basis: auto;

              font-size: 0.9em;

              &.block-alpha {
                background: #05ffb0;
                color: #310736;
              }

              &.block-beta {
                background: #00e7eb;
                color: #310736;
              }

              &.block-pink {
                background: #ff3860;
                color: #310736;
              }
              &.block-yellow {
                background: #ffdd57;
                color: #310736;
              }

              &.block-orange {
                background: #ff470f;
                color: #310736;
              }

              &.block-purple {
                background: #b86bff;
                color: #310736;
              }
            }

            &.basis {
              &.type2 {
                .block {
                  flex-basis: 80px;
                }
              }
            }

            &.direction {
              .block {
                &.block-beta {
                  background: #00e7eb;
                  color: #310736;
                }

                &.block-pink {
                  background: #ff3860;
                  color: #310736;
                }

                &.block-yellow {
                  background: #ffdd57;
                  color: #310736;
                }
              }

              &.row {
                flex-direction: row;
              }

              &.row-reverse {
                flex-direction: row-reverse;
              }

              &.column {
                flex-direction: column;
              }

              &.column-reverse {
                flex-direction: column-reverse;
              }
            }

            &.grow {
              background: #310736;
              border-radius: 3px;
              display: flex;
              padding: 1em;

              &.type2 {
                .block.block-alpha {
                  flex-grow: 1;
                }
              }

              &.type3 {
                .block.block-alpha {
                  flex-grow: 1;
                }
                .block.block-pink {
                  flex-grow: 2;
                }
              }
            }

            &.shrink {
              background: #310736;
              border-radius: 3px;
              display: flex;
              padding: 1em;
              width: 300px;
              word-break: unset;

              .block {
                flex-shrink: 1;

                &.block-alpha {
                  flex-shrink: 1;
                }
              }

              &.type2 {
                .block {
                  &.block-alpha {
                    flex-shrink: 0;
                  }
                }
              }

              &.type3 {
                .block {
                  &.block-alpha {
                    flex-grow: 1;
                    width: 100%;
                  }

                  &.block-pink {
                    flex-shrink: 3;
                    word-break: break-all;
                  }

                  &.block-yellow {
                    flex-shrink: 1;
                    word-break: break-all;
                  }
                }
              }
            }

            &.wrap {
              background: #310736;
              display: flex;
              max-width: 320px;
              font-size: 0.9em;

              .block {
                display: block;
                flex-basis: unset;
              }

              &.nowrap {
                flex-wrap: nowrap;
              }

              &.onwrap {
                flex-wrap: wrap;
              }

              &.wrap-reverse {
                flex-wrap: wrap-reverse;
              }
            }

            &.justify-content {
              background: #310736;
              padding: 0;
              &.type1 {
                justify-content: flex-start;
              }
              &.type2 {
                justify-content: flex-end;
              }
              &.type3 {
                justify-content: center;
              }
              &.type4 {
                justify-content: space-between;
              }
              &.type5 {
                justify-content: space-around;
              }
            }

            &.order {
              background-color: unset;

              .block {
                flex-grow: 1;
                flex-shrink: 1;
              }

              &.type2 {
                .block-pink {
                  order: 1;
                }
              }

              &.type3 {
                .block-pink {
                  order: -1;
                }
              }

              &.type4 {
                .block-alpha {
                  order: 13;
                }

                .block-beta {
                  order: -7;
                }

                .block-yellow {
                  order: 5;
                }
                .block-pink {
                  order: 9;
                }
              }
            }
          }

          //typography
          &.typo {
            &.color {
              font-size: 1.5em;

              &.type1 {
                color: transparent;
              }
              &.type2 {
                color: red;
              }
              &.type3 {
                color: #05ffb0;
              }
              &.type4 {
                color: rgb(50, 115, 220);
              }
              &.type5 {
                color: rgba(0, 0, 0, 0.5);
                background: #05ffb0;
              }
              &.type6 {
                color: hsl(14, 100%, 53%);
              }
              &.type7 {
                color: hsla(14, 100%, 53%, 0.6);
              }
            }

            &.font-family {
              font-size: 1.5em;
              &.type1 {
                font-family: "Source Sans Pro", "Arial", sans-serif;
              }
              &.type2 {
                font-family: serif;
              }
              &.type3 {
                font-family: sans-serif;
              }
              &.type4 {
                font-family: monospace;
              }
              &.type5 {
                font-family: cursive;
              }
              .type6 {
                font-family: fantasy;
              }
            }

            &.font-size {
              .parent {
                font-size: 18px;
              }

              .block {
                font-size: 1.2em;
                margin-top: 0.25rem;
                background: #f2f2f2;
                border-radius: 3px;
                color: #4d4d4d;
                display: block;
                line-height: 1.2;
                padding: 1em;

                &-alpha {
                  background: #05ffb0;
                  color: #310736;
                }

                &-pink {
                  background: #ff3860;
                  color: #310736;
                }

                &-yellow {
                  background: #ffdd57;
                  color: #310736;
                }
              }

              &.type1 {
                font-size: medium;
              }

              &.type2 {
                font-size: 20px;
              }

              &.type3 {
                font-size: 1.2em;
              }

              &.type4 {
                font-size: 1.2rem;

                .block {
                  font-size: 1.2rem;
                }
              }

              &.type5 {
                font-size: 90%;

                .block {
                  font-size: 90%;
                }
              }

              &.type6 {
                font-size: smaller;

                .block {
                  font-size: smaller;
                }
              }

              &.type7 {
                font-size: x-large;

                .block {
                  font-size: x-large;
                }
              }
            }

            &.font-style {
              &.type1 {
                font-style: normal;
              }
              &.type2 {
                font-style: italic;
              }
              &.type3 {
                font-style: oblique;
              }
            }

            &.font-variant {
              &.type1 {
                font-variant: normal;
              }
              &.type2 {
                font-variant: small-caps;
              }
            }

            &.font-weight {
              &.type1 {
                font-weight: normal;
              }
              &.type2 {
                font-weight: bold;
              }
              &.type3 {
                font-weight: 600;
              }
              &.type4 {
                font-weight: lighter;
              }
            }

            &.letter-spacing {
              &.type1 {
                letter-spacing: normal;
              }
              &.type2 {
                letter-spacing: 2px;
              }
              &.type3 {
                letter-spacing: 0.1em;
              }
            }

            &.line-height {
              font-size: 0.8em;
              .block {
                margin-top: 0.25rem;
                background: #f2f2f2;
                border-radius: 3px;
                color: #4d4d4d;
                display: block;
                padding: 1em;

                &-alpha {
                  background: #05ffb0;
                  color: #310736;
                }
              }

              &.type1 {
                line-height: normal;
              }
              &.type2 {
                line-height: 1.6;
              }
              &.type3 {
                line-height: 30px;
              }
              &.type4 {
                line-height: 0.8em;
              }
            }

            &.text-align {
              font-size: 0.8em;
              .block {
                margin-top: 0.25rem;
                background: #f2f2f2;
                border-radius: 3px;
                color: #4d4d4d;
                display: block;
                padding: 1em;
                &-alpha {
                  background: #05ffb0;
                  color: #310736;
                }
              }

              &.type1 {
                text-align: left;
              }
              &.type2 {
                text-align: right;
              }

              &.type3 {
                text-align: center;
              }

              &.type4 {
                text-align: justify;
              }
            }

            &.text-decoration {
              &.type1 {
                text-decoration: none;
              }
              &.type2 {
                text-decoration: underline;
              }
            }

            &.text-indent {
              font-size: 0.8em;
              .block {
                margin-top: 0.25rem;
                background: #f2f2f2;
                border-radius: 3px;
                color: #4d4d4d;
                display: block;
                padding: 1em;

                &-alpha {
                  background: #05ffb0;
                  color: #310736;
                }
              }

              &.type1 {
                text-indent: 0;
              }
              &.type2 {
                text-indent: 40px;
              }
              &.type3 {
                text-indent: -2em;
              }
            }

            &.text-overflow {
              font-size: 0.8em;
              .block {
                margin-top: 0.25rem;
                background: #f2f2f2;
                border-radius: 3px;
                color: #4d4d4d;
                display: block;
                padding: 1em;

                overflow: hidden;
                white-space: nowrap;
                width: 5em;

                &-alpha {
                  background: #05ffb0;
                  color: #310736;
                }
              }

              &.type1 {
                .block {
                  text-overflow: clip;
                }
              }
              &.type2 {
                .block {
                  text-overflow: ellipsis;
                }
              }
            }

            &.text-shadow {
              font-size: 0.8em;
              &.type1 {
                text-shadow: none;
              }
              &.type2 {
                text-shadow: 2px 6px;
              }
              &.type3 {
                text-shadow: 2px 6px red;
              }
              &.type4 {
                text-shadow: 2px 4px 10px red;
              }
            }

            &.text-transform {
              font-size: 0.8em;
              line-height: 1.6;

              &.type1 {
                text-transform: none;
              }
              &.type2 {
                text-transform: capitalize;
              }
              &.type3 {
                text-transform: uppercase;
              }
              &.type4 {
                text-transform: lowercase;
              }
            }

            &.white-space {
              font-family: "Source Code Pro", monospace;
              font-size: 0.7rem;
              width: 18em;
              background: #05ffb0;
              color: #310736;
              display: block;
              line-height: 1.2;
              padding: 1em;
              border-radius: 3px;

              &.type1 {
                white-space: normal;
              }
              &.type2 {
                white-space: nowrap;
              }
              &.type3 {
                white-space: pre;
              }
              &.type4 {
                white-space: pre-wrap;
              }
              &.type5 {
                white-space: pre-line;
              }
            }

            &.word-break {
              font-family: "Source Code Pro", monospace;
              width: 18em;
              background: #05ffb0;
              color: #310736;
              display: block;
              line-height: 1.2;
              padding: 1em;
              border-radius: 3px;
              position: relative;

              &.type1 {
                word-break: normal;
              }

              &.type2 {
                word-break: break-all;
              }
            }

            &.word-spacing {
              &.type1 {
                word-spacing: normal;
              }
              &.type2 {
                word-spacing: 5px;
              }
              &.type3 {
                word-spacing: 2em;
              }
            }
          }

          //transition
          &.transition {
            .square {
              align-items: center;
              border-radius: 3px;
              display: flex;
              height: 75px;
              justify-content: center;
              line-height: 1.2;
              padding: 0;
              text-align: center;
              width: 75px;

              &.square-alpha {
                background: #05ffb0;
                color: #310736;
              }
            }
            &:hover {
              .square {
                background: hsl(348, 100%, 61%);
                color: #fff;
                transform: translateX(200%);
              }
            }

            &.delay {
              &.type1 {
                .square {
                  transition-duration: 1s;
                  transition-timing-function: linear;
                }
              }

              &.type2 {
                .square {
                  transition-duration: 1s;
                  transition-delay: 1.2s;
                  transition-timing-function: linear;
                }
              }

              &.type3 {
                .square {
                  transition-duration: 1s;
                  transition-delay: 2400ms;
                  transition-timing-function: linear;
                }
              }

              &.type4 {
                .square {
                  transition-duration: 1s;
                  transition-delay: -500ms;
                  transition-timing-function: linear;
                }
              }
            }

            &.duration {
              &.type1 {
                .square {
                  transition-duration: 0s;
                  transition-timing-function: linear;
                }
              }

              &.type2 {
                .square {
                  transition-duration: 1.2s;
                  transition-timing-function: linear;
                }
              }

              &.type3 {
                .square {
                  transition-duration: 2400ms;
                  transition-timing-function: linear;
                }
              }
            }

            &.property {
              &.type1 {
                .square {
                  transition-property: all;
                  transition-duration: 1s;
                  transition-timing-function: linear;
                }
              }

              &.type2 {
                .square {
                  transition-property: none;
                  transition-duration: 1s;
                  transition-timing-function: linear;
                }
              }

              &.type3 {
                .square {
                  transition-property: background;
                  transition-duration: 1s;
                  transition-timing-function: linear;
                }
              }

              &.type4 {
                .square {
                  transition-property: color;
                  transition-duration: 1s;
                  transition-timing-function: linear;
                }
              }

              &.type5 {
                .square {
                  transition-property: transform;
                  transition-duration: 1s;
                  transition-timing-function: linear;
                }
              }
            }

            &.timing {
              &.type1 {
                .square {
                  transition-duration: 1s;
                  transition-timing-function: ease;
                }
              }

              &.type2 {
                .square {
                  transition-duration: 1s;
                  transition-timing-function: ease-in;
                }
              }

              &.type3 {
                .square {
                  transition-duration: 1s;
                  transition-timing-function: ease-out;
                }
              }

              &.type4 {
                .square {
                  transition-duration: 1s;
                  transition-timing-function: ease-in-out;
                }
              }

              &.type5 {
                .square {
                  transition-duration: 1s;
                  transition-timing-function: linear;
                }
              }

              &.type6 {
                .square {
                  transition-duration: 1s;
                  transition-timing-function: step-start;
                }
              }

              &.type7 {
                .square {
                  transition-duration: 1s;
                  transition-timing-function: step-end;
                }
              }

              &.type8 {
                .square {
                  transition-duration: 1s;
                  transition-timing-function: steps(4, end);
                }
              }
            }

            &.basic {
              &.type1 {
                .square {
                  transition: 1s;
                }
              }

              &.type2 {
                .square {
                  transition: 1s linear;
                }
              }

              &.type3 {
                .square {
                  transition: background 1s linear;
                }
              }

              &.type4 {
                .square {
                  transition: background 1s linear 500ms;
                }
              }

              &.type5 {
                .square {
                  transition:
                    background 4s,
                    transform 1s;
                }
              }
            }
          }

          //positioning
          &.positioning {
            @keyframes fadeIn {
              0% {
                opacity: 0;
              }

              100% {
                opacity: 1;
              }
            }

            .block {
              display: block;
              line-height: 1.2;
              padding: 1em;
              border-radius: 3px;

              &-alpha {
                background: #05ffb0;
                color: #310736;
              }

              &-pink {
                background: #ff3860;
                color: #310736;
              }

              &-beta {
                background: #00e7eb;
                color: #310736;
              }

              &-yellow {
                background: #ffdd57;
                color: #310736;
              }
            }

            background: #310736;
            height: 200px;
            position: relative;
            font-size: 0.8em;

            p {
              color: #fff;
            }
            .natural {
              border: 2px dotted #ff4538;
              color: #ff4538;
            }

            .actual,
            .natural {
              border-radius: 3px;
              line-height: 1.2;
              padding: 0.8em 1em;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .actual {
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              background: #05ffb0;
              border: 2px solid #05ffb0;
              color: #310736;
            }

            .natural {
              width: 200px;
            }

            .actual {
              animation-direction: alternate;
              animation-duration: 2s;
              animation-iteration-count: infinite;
              position: absolute;
              width: 200px;
              animation-name: fadeIn;
              transform: translateY(-100%);
            }

            &.bottom {
              &.type2 {
                .actual {
                  bottom: 20px;
                  position: relative;
                }
              }

              &.type3 {
                .actual {
                  bottom: 0;
                  animation-name: none;
                  transform: unset;
                }
              }
            }

            &.left {
              &.type1 {
                .actual {
                  left: auto;
                }
              }

              &.type2 {
                .actual {
                  left: 80px;
                }
              }

              &.type3 {
                .actual {
                  left: -20px;
                }
              }
            }

            &.basic {
              .block-pink {
                position: absolute;
                right: 5px;
                top: 5px;
              }

              .block-alpha {
                margin-top: 1em;
              }

              &.type2 {
                .block-alpha {
                  position: relative;
                }
              }

              &.type3 {
                .block-alpha {
                  left: 0;
                  margin-top: 0;
                  position: absolute;
                  top: 0;
                  width: 280px;
                }
              }

              &.type4 {
                .block-alpha {
                  // right: 0;
                  // top: 0;
                  // position: fixed;
                  left: auto;
                  right: 0;
                  margin-top: 0;
                  position: absolute;
                  top: 0;
                  width: 280px;

                  &.clicked {
                    position: fixed;
                    z-index: 1;
                  }
                }
              }
            }

            &.right {
              &.type1 {
                .actual {
                  right: auto;
                }
              }
              &.type2 {
                .actual {
                  right: 80px;
                  position: relative;
                  transform: translateY(-100%);
                }
              }

              &.type3 {
                position: relative;
                .actual {
                  right: -20px;
                  transform: translateY(-100%);
                }
              }
            }

            &.top {
              &.type2 {
                .actual {
                  top: 20px;
                  position: relative;
                }
              }

              &.type3 {
                .actual {
                  top: 0;
                  animation-name: none;
                  transform: unset;
                }
              }
            }

            &.z-index {
              background-color: unset;
              height: 100px;
              padding: 0;
              .block {
                height: 100%;
                left: 0;
                position: absolute;
                width: 25%;
                &.block {
                  &-alpha {
                    height: 50%;
                    width: 100%;
                  }
                  &-pink {
                    height: 60%;
                    left: 20%;
                    top: 20%;
                    width: 60%;
                  }
                  &-beta {
                    height: 60%;
                    left: 40%;
                    top: 40%;
                    width: 60%;
                  }
                  &-yellow {
                    left: 70%;
                    width: 20%;
                  }
                }
              }

              &.type2 {
                .block-pink {
                  z-index: 1;
                }
              }

              &.type3 {
                .block-pink {
                  z-index: -1;
                }
              }
            }
          }

          //css-grid
          &.css-grid {
            display: grid;
            padding: 0;
            font-size: 0.8em;

            .block {
              display: block;
              line-height: 1.2;
              padding: 1em;
              border-radius: 3px;

              &-alpha {
                background: #05ffb0;
                color: #310736;
              }

              &-pink {
                background: #ff3860;
                color: #310736;
              }

              &-beta {
                background: #00e7eb;
                color: #310736;
              }

              &-yellow {
                background: #ffdd57;
                color: #310736;
              }

              &-purple {
                background: #b86bff;
                color: #310736;
              }

              &-orange {
                background: #ff470f;
                color: #310736;
              }
            }

            &.area {
              display: grid;
              grid-template-columns: 1fr 1fr 1fr;
              padding: 0;

              &.type1 {
                grid-area: auto;
              }

              &.type2 {
                grid-template-columns: 3fr 70fr;
                grid-template-rows: 50px 200px 50px;
                grid-template-areas:
                  "header header header"
                  "sidebar main main"
                  "sidebar main main"
                  "sidebar footer footer";

                .block {
                  &-alpha {
                    grid-area: header;
                  }

                  &-beta {
                    grid-area: sidebar;
                  }
                  &-purple {
                    grid-area: footer;
                  }
                  &-pink {
                    grid-row: main;
                  }
                }
              }
            }

            &.auto-columns {
              font-size: 0.8em;
              display: grid;
              padding: 0;

              &.type2 {
                grid-template-areas:
                  "header header header"
                  "sidebar main main";
                grid-template-columns: 50px 200px;
                grid-auto-columns: 100px;

                .block-alpha {
                  grid-area: header;
                }
                .block-beta {
                  grid-area: sidebar;
                }
                .block-pink {
                  grid-area: main;
                }
              }
            }

            &.auto-flow {
              grid-template-areas:
                "header header header"
                "sidebar main main"
                "footer footer footer";
              padding: 0;
              grid-auto-flow: row;

              .block-alpha {
                grid-area: header;
              }
              .block-beta {
                grid-area: sidebar;
              }
              .block-pink {
                grid-area: main;
              }

              .block-yellow {
                grid-area: footer;
              }

              &.type2 {
                grid-auto-flow: column;
              }
            }

            &.auto-rows {
              &.type1 {
                grid-auto-rows: auto;
              }

              &.type2 {
                grid-template-areas:
                  "header header header"
                  "sidebar main main"
                  "footer footer footer";
                grid-template-rows: 50px 200px;
                grid-auto-rows: 100px;

                .block-alpha {
                  grid-area: header;
                }
                .block-beta {
                  grid-area: sidebar;
                }
                .block-pink {
                  grid-area: main;
                }

                .block-yellow {
                  grid-area: footer;
                }
              }
            }

            &.column-end {
              grid-template-columns: 1fr 1fr 1fr;
              &.type1 {
                grid-column-end: auto;
              }

              &.type2 {
                grid-column-end: 2;

                .block-pink {
                  grid-column-end: 2;
                }
              }

              &.type3 {
                grid-column-end: 1;

                .block-pink {
                  grid-column-end: 1;
                }
              }
            }

            &.column-gap {
              grid-template-columns: auto auto auto;
              &.type1 {
                grid-column-gap: 0;
              }

              &.type2 {
                grid-column-gap: 10px;
              }

              &.type3 {
                grid-column-gap: 3rem;
              }

              &.type4 {
                grid-column-gap: 20%;
              }
            }

            &.column-start {
              grid-template-columns: 1fr 1fr 1fr;
              &.type1 {
                grid-column-start: auto;
              }

              &.type2 {
                grid-column-start: 2;

                .block-pink {
                  grid-column-start: 2;
                }
              }

              &.type3 {
                grid-column-start: 4;

                .block-pink {
                  grid-column-start: 4;
                }
              }
            }

            &.column {
              grid-column: auto auto;
              grid-template-columns: 1fr 1fr 1fr;

              &.type2 {
                grid-column: 1 / 3;

                .block-pink {
                  grid-column: 1 / 3;
                }
              }

              &.type3 {
                grid-column: span 3;

                .block-pink {
                  grid-column: span 3;
                }
              }

              &.type4 {
                grid-column: 1 / span 4;

                .block-pink {
                  grid-column: 1 / span 4;
                }
              }

              &.type5 {
                grid-template-columns: 3fr 70fr;
                grid-template-rows: 50px 200px 50px;
                grid-template-areas:
                  "header header header"
                  "sidebar main main"
                  "sidebar main main"
                  "sidebar footer footer";

                .block-alpha {
                  grid-area: header;
                }
                .block-beta {
                  grid-area: sidebar;
                }

                .block-yellow {
                  grid-area: main;
                }
                .block-purple {
                  grid-area: footer;
                }

                .block-pink {
                  grid-column: main;
                }
              }
            }

            &.gap {
              grid-template-columns: auto auto auto;
              &.type1 {
                grid-gap: 0 0;
              }

              &.type2 {
                grid-gap: 10px;
              }

              &.type3 {
                grid-gap: 3rem 1rem;
              }
            }

            &.row-end {
              grid-template-columns: 1fr 1fr 1fr;

              &.type1 {
                grid-row-end: auto;
              }

              &.type2 {
                .block-pink {
                  grid-row-end: 3;
                }
              }

              &.type3 {
                .block-pink {
                  grid-row-end: 4;
                }
              }
            }

            &.row-gap {
              grid-template-columns: auto auto auto;

              &.type1 {
                grid-row-gap: 0;
              }

              &.type2 {
                grid-row-gap: 10px;
              }

              &.type3 {
                grid-row-gap: 3rem;
              }
            }

            &.row-start {
              grid-template-columns: 1fr 1fr 1fr;
              &.type2 {
                .block-pink {
                  grid-row-start: 2;
                }
              }

              &.type3 {
                .block-pink {
                  grid-row-start: 3;
                }
              }
            }

            &.row {
              grid-template-columns: 1fr 1fr 1fr;

              &.type2 {
                .block-pink {
                  grid-row: 1 / 3;
                }
              }

              &.type3 {
                .block-pink {
                  grid-row: span 3;
                }
              }

              &.type4 {
                .block-pink {
                  grid-row: 1 / span 4;
                }
              }

              &.type5 {
                grid-template-columns: 3fr 70fr;
                grid-template-rows: 50px 200px 50px;
                grid-template-areas:
                  "header header header"
                  "sidebar main main"
                  "sidebar main main"
                  "sidebar footer footer";

                .block-alpha {
                  grid-area: header;
                }
                .block-beta {
                  grid-area: sidebar;
                }
                .block-yellow {
                  grid-area: main;
                }
                .block-purple {
                  grid-area: footer;
                }
                .block-pink {
                  grid-row: header;
                }
              }
            }

            &.template-area {
              &.type2 {
                grid-template-areas:
                  "header header header"
                  "sidebar main main";

                .block-alpha {
                  grid-area: header;
                }

                .block-beta {
                  grid-area: sidebar;
                }

                .block-pink {
                  grid-area: main;
                }
              }
            }

            &.template-columns {
              grid-template-columns: none;

              &.type2 {
                grid-template-columns: auto auto auto;
              }

              &.type3 {
                grid-template-columns: 80px auto 1rem;
              }

              &.type4 {
                grid-template-columns: 40px 1fr 2fr;
              }
            }

            &.template-rows {
              grid-template-columns: auto auto;
              &.type2 {
                grid-template-rows: 120px auto 3rem;
              }
              &.type3 {
                grid-template-rows: 20px 1fr 2fr;
              }
            }

            &.template {
              &.type1 {
                grid-template: none;
              }

              &.type2 {
                grid-template: 200px 1fr / 100px auto 3rem;
              }

              &.type3 {
                grid-template: "header header header" 50px "sidebar main main" 200px / 100px auto;

                .block-alpha {
                  grid-area: header;
                }
                .block-beta {
                  grid-area: sidebar;
                }
                .block-pink {
                  grid-area: main;
                }
              }
            }

            &.grid {
              &.type1 {
                grid:
                  "header header header" 50px
                  "sidebar main main" 200px / 100px auto;

                .block-alpha {
                  grid-area: header;
                }
                .block-beta {
                  grid-area: sidebar;
                }
                .block-pink {
                  grid-area: main;
                }
              }

              &.type2 {
                grid: 200px 100px / auto-flow 30%;
              }

              &.type3 {
                grid: auto-flow 50px / 200px 100px;
              }
            }
          }

          //animations
          &.animations {
            padding: 0;
            font-size: 0.8em;

            @keyframes fadeAndMove {
              0% {
                opacity: 0;
                transform: translateX(0);
              }
              100% {
                opacity: 1;
                transform: translateX(200px);
              }
            }

            @keyframes kf-animation-fill-mode {
              0% {
                background: #23d160;
                -webkit-transform: translateX(0);
                transform: translateX(0);
              }
              100% {
                background: #3273dc;
                -webkit-transform: translateX(200px);
                transform: translateX(200px);
              }
            }

            @keyframes fadeIn {
              from {
                opacity: 0;
              }
              to {
                opacity: 1;
              }
            }

            @keyframes moveRight {
              from {
                transform: translateX(0);
              }
              to {
                transform: translateX(200px);
              }
            }

            &.ani-on {
              animation-name: fadeAndMove;
            }

            &.ani-on2 {
              animation-play-state: running !important;
            }

            &.ani-on3 {
              animation-duration: 2s;
            }

            &.ani-on4 {
              animation-name: moveRight;
            }

            &.square {
              display: flex;
              height: 75px;
              align-items: center;
              justify-content: center;
              line-height: 1.2;
              padding: 0;
              text-align: center;
              width: 75px;
              background: #310736;
              color: #fff;
              border-radius: 3px;
            }

            &.delay {
              animation-duration: 5s;
              animation-iteration-count: infinite;

              &.type1 {
                animation-delay: 0s;
              }

              &.type2 {
                animation-delay: 1.2s;
              }

              &.type3 {
                animation-delay: 2400ms;
              }

              &.type4 {
                animation-delay: -500ms;
              }
            }

            &.direction {
              animation-duration: 2s;
              animation-iteration-count: infinite;
              &.type1 {
                animation-direction: normal;
              }
              &.type2 {
                animation-direction: reverse;
              }
              &.type3 {
                animation-direction: alternate;
              }
              &.type4 {
                animation-direction: alternate-reverse;
              }
            }

            &.duration {
              animation-iteration-count: infinite;
              &.type1 {
                animation-duration: 0s;
              }
              &.type2 {
                animation-duration: 1.2s;
              }
              &.type3 {
                animation-duration: 2400ms;
              }
            }

            &.fill-mode {
              animation-delay: 1s;
              animation-duration: 2s;
              animation-iteration-count: 1;
              animation-name: kf-animation-fill-mode;
              animation-play-state: paused;

              &.square {
                background: #ff3860;
              }

              &.type1 {
                animation-fill-mode: none;
              }

              &.type2 {
                animation-fill-mode: forwards;
              }

              &.type3 {
                animation-fill-mode: backwards;
              }

              &.type4 {
                animation-fill-mode: both;
              }
            }

            &.iteration-count {
              animation-duration: 2s;

              &.type1 {
                animation-iteration-count: 1;
              }
              &.type2 {
                animation-iteration-count: 2;
              }
              &.type3 {
                animation-iteration-count: infinite;
              }
            }

            &.name {
              animation-iteration-count: infinite;
              &.type1 {
                animation-name: none;
              }
              &.type2 {
                animation-name: fadeIn;
              }
              &.type3 {
                animation-name: moveRight;
              }
            }

            &.play-state {
              animation-duration: 2s;
              animation-iteration-count: infinite;
              &.type1 {
                animation-play-state: running;
              }
              &.type2 {
                animation-play-state: paused;
              }
            }

            &.timing-function {
              animation-duration: 3s;
              animation-fill-mode: forwards;
              &.type1 {
                animation-timing-function: ease;
              }

              &.type2 {
                animation-timing-function: ease-in;
              }

              &.type3 {
                animation-timing-function: ease-out;
              }

              &.type4 {
                animation-timing-function: ease-in-out;
              }
              &.type5 {
                animation-timing-function: linear;
              }
              &.type6 {
                animation-timing-function: step-start;
              }
              &.type7 {
                animation-timing-function: step-end;
              }
              &.type8 {
                animation-timing-function: steps(4, end);
              }
            }
          }
        }
      }
    }

    .contents-box {
      &-top {
        background-color: #f2f2f2;
        border: 1px solid #e6e6e6;
        border-bottom: none;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        height: 1rem;
      }
      &-output {
        background-color: #fff;
        border: 1px solid #e6e6e6;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
        padding: 0.5em;
      }
    }
  }
}

// 반응형
@media screen and (max-width: 800px) {
  .css-reference-content .contents-tab {
    flex-direction: column;
  }

  .css-reference-content .contents-tab .contents-title,
  .css-reference-content .contents-tab .contents-example {
    width: 100%;
  }

  .css-reference-content {
    padding: 0 2rem 2rem;
  }

  .css-reference-content .name {
    font-size: 1rem;
    padding: 1rem 0;
  }

  .css-reference-content .define {
    font-size: 0.9rem;
  }

  .css-reference-content .contents-tab .property-name,
  .css-reference-content .contents-tab .property-name-default,
  .css-reference-content .contents-tab .property-define {
    font-size: 0.8rem;
  }

  .css-reference-content .contents-tab {
    gap: 1rem;
  }

  .menu-type.css ul {
    gap: 5px;
    padding: 0;
    font-size: 0.8rem;
  }

  .menu-type.css.category li {
    font-size: 0.7rem;
  }

  .menu-type.css {
    margin: 15px 0 10px;
  }

  .menu-type.css.category {
    margin: 0 0 15px;
  }

  .css-reference-content .contents-tab .contents-output .example-background-output.typo.font-family {
    font-size: 1em;
  }
}
