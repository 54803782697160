@charset 'UTF-8';

@import "../../assets/css/variables";

.service-list {
  display: flex;
  margin-top: -30px;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -10px 0 -10px;

  li {
    margin-top: 30px;
    flex-basis: 25%;
    align-self: stretch;
    padding: 0 10px;

    a {
      &:hover {
        .service-box {
          padding: 25px;

          &::after {
            opacity: 0.1;
          }
        }
      }
    }
  }
}

.service-box {
  position: relative;
  height: 100%;
  padding: 20px;
  border-radius: 10px;
  transition: padding 0.4s cubic-bezier(0.23, 1, 0.32, 1);

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0;
    transition: opacity 0.3s;
  }
}

.service-title {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 29px 10px;
  color: #fff;
  border: 2px dashed #fff;
  border-radius: 10px;
  text-align: center;
  z-index: 1;

  span {
    display: block;
    color: rgba(255, 255, 255, 0.6);
    font-size: 16px;
    font-weight: 300;
    letter-spacing: -0.64px;
  }

  .service-category {
    margin-bottom: 5px;
  }

  h3 {
    font-size: 30px;
    font-weight: 500;
    letter-spacing: -1.2px;
  }

  .service-wait {
    margin-top: 7px;
  }
}

.design {
  .service-box {
    background-color: #e91422;
  }

  .service-title {
    background-color: #ff2a38;
    box-shadow: 6.3px 7.8px 10px 0 rgba(196, 9, 22, 0.24);
  }
}

.image {
  .service-box {
    background-color: #ee6a12;
  }

  .service-title {
    background-color: #ff7c24;
    box-shadow: 6.3px 7.8px 10px 0 rgba(196, 9, 22, 0.24);
  }
}

.html {
  .service-box {
    background-color: #f8c534;
  }

  .service-title {
    background-color: #ffd04a;
    box-shadow: 6.3px 7.8px 10px 0 rgba(224, 167, 12, 0.24);
  }
}

.font {
  .service-box {
    background-color: #0f4c81;
  }

  .service-title {
    background-color: #48689a;
    box-shadow: 6.3px 7.8px 10px 0 rgba(15, 76, 129, 0.24);
  }
}

.css {
  .service-box {
    background-color: #f8c534;
  }

  .service-title {
    background-color: #ffd04a;
    box-shadow: 6.3px 7.8px 10px 0 rgba(224, 167, 12, 0.24);
  }
}
