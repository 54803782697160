@charset 'UTF-8';

@import '../../assets/css/variables';

.em-table {
  width: 100%;
  max-width: 570px;
  margin: 0 auto;

  table {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;

    th {
      padding: 9px 0;
      color: $main;
      font-size: 16px;
    }

    td {
      padding: 10px 0;
      color: #333;
      border-top: 1px solid #d2ecfc;
      font-size: 14px;
    }

    .default {
      background-color: #d2ecfc;
    }
  }

  caption {
    margin-bottom: 37px;
    color: #333;
    font-size: 18px;
    font-weight: 500;
  }
}