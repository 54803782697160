@charset 'UTF-8';

@import '../../assets/css/variables';

/*************************************
    header
*************************************/
#header {
  position: relative;
  width: 100%;
  padding: 37px 0;
  background-color: #fff;
  transition: background-color 0.2s ease-in;

  &.sticky {
    position: fixed;
    top: 0;
    background-color: $main;
    z-index: 9;

    .header-logo a {
      background: url(../../assets/images/common/toolo-logo-sticky.png) center center / cover no-repeat;
    }

    path {
      stroke: #fff;
    }
  }
}

#headSvg {
  position: absolute;
  top: 28px;
  left: 80px;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.header-logo {
  a {
    display: block;
    position: relative;
    width: 77px;
    height: 27px;
    margin: 0 auto;
    background: url(../../assets/images/common/toolo-logo.png) center center / cover no-repeat;
    text-indent: -9999px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: -10px;
      right: -32px;
      width: 34px;
      height: 34px;
      background: url(../../assets/images/common/toolo-logo-deco.png) center center / cover no-repeat;
      animation: rotate 3s linear infinite both;
    }
  }

  &.white {
    a {
      width: 100px;
      height: 34px;
      background: url(../../assets/images/common/toolo-logo-white.png) center center / cover no-repeat;

      &::after {
        display: none;
      }
    }
  }
}

svg {
  width: 54px;
  height: 40px;
  cursor: pointer;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

path {
  fill: none;
  -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
  -moz-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
  -o-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
  -ms-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
  transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
  stroke-width: 25px;
  stroke-linecap: round;
  stroke: $main;
  stroke-dashoffset: 0px;
}

path#gnbBtnTop,
path#gnbBtnBottom {
  stroke-dasharray: 240px 950px;
}

path#gnbBtnMiddle {
  stroke-dasharray: 240px 240px;
}

.cross path#gnbBtnTop,
.cross path#gnbBtnBottom {
  stroke-dashoffset: -650px;
  stroke-dashoffset: -650px;
}

.cross path#gnbBtnMiddle {
  stroke-dashoffset: -115px;
  stroke-dasharray: 1px 220px;
}

/*************************************
    footer
*************************************/
#footer {
  width: 100%;
  line-height: 2;
  padding: 29px 0;
  color: #fff;
  background-color: #05304a;
  font-size: 12px;
  font-weight: 300;
  text-align: center;
}