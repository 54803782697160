@charset 'UTF-8';

@import '../../assets/css/variables';

.picker-box {
  padding-left: 70px;

  .chrome-picker {
    width: 100% !important;
  }
}

.content-wrap .flex-box {
  .picker-info {
    padding-left: 50px;
    text-align: left;
  }
}

.picker-info-text {
  padding-bottom: 16px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  color: #333;
  font-size: 16px;
  line-height: 1.4;

  b {
    color: $main;
  }
}

.result-css {
  padding: 25px 40px;
  background-color: #fff;
  border-radius: 10px;

  label {
    display: block;
    margin-bottom: 8px;
    color: #333;
    font-size: 16px;
    font-weight: 500;
  }

  input {
    width: 100%;
    height: 36px;
    background-color: #f5f5f5;
    border: none;
    border-radius: 10px;
    padding: 0 20px;
  }
}

.result-css-title {
  display: block;
  color: $main;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.result-box {
  margin-top: 8px;
}